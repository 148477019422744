.hours-container {
  width: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    width: 100%;
  }
}
.hours-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #333333;
  font-size: 25px;
  margin: 0px 0px 20px;
}
.range-bar-wrapper {
  display: flex;
  flex-direction: column;
  height: 180px;
  margin-bottom: 30px;
}
