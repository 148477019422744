@import 'styles/base/color.scss';


.svg-icon {
  vertical-align: text-top;
}
.svg-icon-dis {
  filter: grayscale(1);
  vertical-align: text-top;
  opacity: 0.5;
}

button.assign-button{
  &:focus {
    background-color: #5a87ef;
  }
}
.users-button-wrapper {

  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: 20px;
  }
}


.users-dialog-root {
  min-width: 380px;


  &__box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .users-dialog-button {
    text-transform: capitalize;
    margin: 0 20px;
    min-width: 100px;

    &:disabled {
      background-color: white;
    }

    &__save:hover {
      color: $palette-primary;
    }

    &__cancel:hover {
      color: inherit;
    }
  }
}