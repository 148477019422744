.DayPickerInput {
  width: 100%;
}

.DayPickerInput > input {
  margin: 8px 0;
  box-shadow: none;
  border: 1px solid #d9dae3;
  border-radius: 5px;
}

.DayPickerInput > input:disabled {
  background-color: #ddd;
  cursor: default;
}

@media (min-width: 992px) {
  .endDatePicker .DayPickerInput-Overlay {
    left: auto;
    right: 0;
  }
  .dayPickerWrapperMob.endDatePicker .DayPickerInput-Overlay {
    left: auto;
    right: auto;
  }
}

.fullWidth .DayPickerInput {
  width: 100% !important;
}

.sm .DayPickerInput > input {
  font-size: 12.8px;
  line-height: 0.75;
  padding: 5.4px;
  height: auto;
  margin-bottom: 0;
  margin-top: 7.5px;
}

.DayPicker-Day--weekends {
  color: #d65454;
}

.DayPicker-Day--disabled {
  color: #d9dae3;
}

@media (max-width: 999px) {
  .DayPickerInput > input {
    width: 320px !important;
    margin-bottom: 12px;
  }
}
@media (max-width: 680px) {
  .fullWidth .DayPickerInput {
    width: auto !important;
  }
  .DayPickerInput > input {
    width: 228px !important;
  }
}
@media (max-width: 530px) {
  .DayPickerInput > input {
    width: 340px !important;
  }
}
@media (max-width: 330px) {
  .DayPickerInput > input {
    width: 300px !important;
  }
}
