.super-admin-page {
  &__wrapper {
    display: flex;
    width: 100vw;
    column-gap: 55px;
  }
}

.super-admin-tabs {
  min-height: calc(100vh - 55px);
  border-right: 2px solid #d9dae3;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  
  
  .MuiTab-root {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0 8px 40px;
    height: 45px;
  }

  .MuiTab-root:focus {
    background-color: #6588e817 !important;
    .super-admin-tab-icon {
      filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(212deg) brightness(119%)
        contrast(119%);
    }
  }
  .MuiTab-root:hover {
    background-color: #6588e817 !important;
    .super-admin-tab-icon {
      filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(212deg) brightness(119%)
        contrast(119%);
    }
  }

  .MuiTab-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    width: 140px;
  }

  .super-admin-tab-icon {
    width: 20px;
    margin-bottom: 0 !important;
    margin-right: 14px;
  }

  .MuiTab-labelIcon {
    min-height: auto !important;
  }

  .Mui-selected {
    background-color: #6588e817;
    border-left: 2px solid #6588e8;
    padding-left: 38px;
    color: #6588e8;
    .super-admin-tab-icon {
      filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(212deg) brightness(119%)
        contrast(119%);
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-textColorInherit {
    opacity: 1;
  }
}


.super-admin-info-block {
  margin-top: 30px;
  width: 100%;
  padding-right: 30px;
  max-width: 1500px;
}