@import 'styles/base/color.scss';



.client-cell-login.disabled {
    
  & > .client-cell-button {
    color: $palette-grey;
    pointer-events: none;
  }
}