@import './_settings.scss';
@import './availability.scss';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

$border-color: #ccc;

.text-black,
.text-black div {
  color: #000 !important;
}

.hidden {
  visibility: hidden !important;
}
.day-label {
  background-color: $day-color !important;
  color: $text-color !important;
  border-color: #dae55b;
  &.used-resource {
    $red: red($day-color);
    $green: green($day-color);
    $blue: blue($day-color);
    background: rgba($red, $green, $blue, 0.5) !important;
    border: none;
    color: lighten($text-color, 30%) !important;
  }
}

.night-label {
  background-color: $night-color !important;
  color: $text-inverse-color !important;
  color: white;
  &.used-resource {
    $red: red($night-color);
    $green: green($night-color);
    $blue: blue($night-color);
    background: rgba($red, $green, $blue, 0.5) !important;
    border: none;
    color: lighten($text-inverse-color, 30%) !important;
  }
}

.no-label {
  background-color: $no-label-color !important;
  color: $text-inverse-color;
  &.used-resource {
    $red: red($no-label-color);
    $green: green($no-label-color);
    $blue: blue($no-label-color);
    background: rgba($red, $green, $blue, 0.5) !important;
    border: none;
    color: lighten($text-inverse-color, 30%) !important;
  }
}

.item-wrapper {
  &:hover {
    background-color: darken(#fff, 30%);
  }
  span {
    padding: 4px 8px !important;
    border-radius: 5px;
    &.ui-state-active {
      border: none;
    }
  }
}

.equals {
  padding: 0 15px;
}

.flex-row {
  display: flex;
}
.flex-item {
  flex-grow: 1;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
}

.time-select {
  width: 30%;
}
/* List of resources next to calendar */
.drag {
  cursor: move;
  user-select: none;
}

.schedule-external-items {
  $padding-sides: 10px;

  padding: 0 $padding-sides;
  // overflow: hidden;

  th {
    padding-bottom: 5px;

    hr {
      margin: 8px 0 0;
      border-color: #dadada;
    }
  }

  tr {
    background-color: #f3f2f2 !important;

    td {
      padding: 5px $padding-sides;
      padding-right: 0;
      max-width: 105px;
    }
  }
}

.custom-resourcesItemsList,
#events .custom-resourcesItemsList {
  display: inline-block;
  height: 22px;
  font-size: 12px;
  line-height: 1.9;
  padding: 0 5px;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// .used-resource {
//     opacity: 0.4;
// }
.unavailable-resource {
  background-color: #b4b4b4 !important;
  color: #fff !important;
}
.unavailable-resource-wrapper {
  border: 1px solid #8b8b8b;
  padding: 3px 4px 0;
  width: fit-content;
}

.container {
  height: 1080px;
  width: 900px;
  display: inline-flex;
}

div.projectResources {
  height: calc(100% - 47px);
  overflow: hidden;
}
div.resource > p {
  height: 25px;
  margin-left: 10px;
  margin-bottom: 20px;
}
div.moreResources {
  font-size: 200%;
  text-align: center;
  vertical-align: middle;
}

.eventContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .notes-sidebar {
    word-wrap: break-word;
  }

  .title {
    line-height: 30px;
    padding: 0 25px 0 10px;
    font-size: 14px;
    border-bottom: 1px solid #cccccc;

    i.fi-x {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  dl {
    padding: 10px 0;
    font-size: 14px;
  }

  dl dt,
  dl dd {
    margin: 0;
    line-height: 21px;
    padding-left: 10px;
    padding-right: 10px;
  }
  dl dd {
    margin: 0 0 5px 0;
  }
}

.equipment {
  color: #fff;
  &.used-resource {
    opacity: 0.4;
  }
}
.equipment-token {
  color: #fff;
  &.used-resource {
    opacity: 0.6;
  }
}

//schedule calendar
#gridEvents {
  .fc-view,
  .fc-view-container {
    height: 1px !important;
    padding: 0 !important;
    width: 1px !important;
  }
  .schedule-event {
    margin: 7px auto 0;
    width: calc(100% - 20px) !important;
    height: 180px !important;

    .rts-banner {
      width: 100% !important;
    }
    .fc-content {
      z-index: 2;
      top: 1px;
    }
    .fc-bg {
      background-color: rgb(243, 242, 242) !important;
    }

    &.has-extra-resources:hover {
      .resourcesWrapper {
        max-height: none;
        background-color: rgb(243, 242, 242);
      }
      .peopleContainer > div {
        display: block !important;
        &:last-child {
          margin-bottom: 20px;
        }
      }
      .equipmentContainer > div {
        display: block !important;
        &:last-child {
          margin-bottom: 20px;
        }
      }
      .over-limit-count {
        display: none;
      }
      .fc-bg {
        height: max-content;
        z-index: 100;
      }
      .fc-content {
        z-index: 101;
        top: 1px;
      }
    }
  }
  table.grid-table {
    display: table;
  }
  .over-limit-count {
    position: absolute;
    color: blue;
    bottom: 20px;
    left: 5px;
  }

  td.drop-hover {
    background-color: #8ec6ff;
  }
}

#events,
#gridEvents {
  $event-border-color: #b2b3a7;
  $left-padding: 6px;

  margin-top: $topbar-height;
  margin-left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});

  box-sizing: border-box;
  position: relative;

  .fc-day-grid-event {
    .fc-time {
      display: block;
      font-weight: normal;
    }
    & > .fc-content {
      display: block;
      max-width: 100%;
      padding: 0;
    }
    .resourcesWrapper {
      box-sizing: border-box;
      max-height: 110px;
    }
  }

  .grid-table {
    min-width: 1100px !important;
    td {
      width: 205px;
      height: 200px;
      border: 3px solid #000;
    }
  }

  > .fc-view-container {
    padding: 13px 0 0 10px;
  }

  .fc-view {
    overflow-x: auto;
    height: calc(100vh - #{$schedule-margin});
  }

  .fc-scroller {
    overflow: inherit !important;
    height: calc(100vh - #{$schedule-margin}) !important;
  }

  .fc-axis.fc-time span {
    position: absolute;
    top: -10px;
    right: 2px;
  }

  .fc-time-grid .fc-slats td {
    height: 8.5em;
  }

  table td,
  table tr,
  table {
    border: none;
    background-color: #fff;
  }

  .fc-time-grid > .fc-bg {
    display: none;
  }

  .fc-scroller > .fc-time-grid,
  .fc-scroller > .fc-day-grid {
    margin-top: 7px;
  }

  .fc-head {
    display: none;
  }

  .fc-axis.fc-time {
    position: relative;
    border: none;
  }

  .fc-slats {
    table tr .fc-widget-content:nth-child(2) {
      border-top: 1px solid #e8e8e8;
    }

    tr:last-child > td {
      height: 1px;
    }
  }

  .fc-content {
    border-radius: 5px 5px 0 0;
    z-index: 0;
  }

  .fc-event .fc-bg {
    opacity: 1;
    background: transparent;
    border: 1px solid $event-border-color;
    border-radius: 5px 5px 5px 5px;

    &.chosenEvent {
      $shadow: 0px 0px 5px 2px #8ec6ff;

      -webkit-box-shadow: $shadow;
      -moz-box-shadow: $shadow;
      box-shadow: $shadow;

      &.notReconciled {
        $shadow: 0px 0px 5px 2px red;

        -webkit-box-shadow: $shadow;
        -moz-box-shadow: $shadow;
        box-shadow: $shadow;
      }
    }
    &.notReconciled {
      border-color: red;
    }

    &.missingCL {
      $shadow: 0px 0px 5px 2px red;

      -webkit-box-shadow: $shadow;
      -moz-box-shadow: $shadow;
      box-shadow: $shadow;
      border-color: red;
      border-color: red;
    }

    & > .dwl-banner {
      font-size: 12px;
      position: absolute;
      left: 50%;
      top: calc(50% + 12px);
      transform: translate(-50%, -50%);
      width: 100%;
      max-height: calc(100% - 24px);
      text-align: center;
      color: #000;
      border: 1px solid #000;
      border-radius: 5px;
      opacity: 0.6;
      background: #fafafa;
      z-index: 2000;
    }

    & > .unpublished-banner {
      font-size: 12px;
      position: absolute;
      left: 50%;
      top: calc(50% + 12px);
      transform: translate(-50%, -50%);
      width: 100%;
      max-height: calc(100% - 24px);
      text-align: center;
      color: red;
      border: 1px solid #000;
      border-radius: 5px;
      opacity: 0.6;
      background: #fafafa;
      z-index: 2000;
    }

    & > .rts-banner {
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 99%;
      text-align: center;
      color: #000;
      border: 1px solid #000;
      border-radius: 5px;
      opacity: 0.6;
      background: #fafafa;
      z-index: 2000;
      & > button {
        height: auto;
        width: 100%;
        border-radius: 5px;
        background: inherit;
        color: #000;
        padding: 2px 0;
      }
    }
    & > .rts-checkbox {
      font-size: 12px;
      position: absolute;
      right: 6px;
      bottom: 24px;
      text-align: center;
      color: #000;
      opacity: 0.9;
      background: #fafafa;
      z-index: 2000;
      width: 20px;
      height: 20px;
      & > input[disabled] {
        margin: 0;
        width: 20px;
        height: 20px;
        zoom: 1.1;
      }
    }

    .sheetNote {
      display: flex;
      align-items: center;
      // margin-top: 22px;
      color: #4d4d4d;
      font-size: 10px;
      padding-left: $left-padding;
      // border-top: solid 1px $event-border-color;
      border-bottom: solid 1px $event-border-color;
      background-color: #fffde9;

      & > p {
        margin: 1px 0 -1px;
        display: block;
        font-size: 1em;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        max-height: 5em;
        min-height: 20px;
      }
    }

    .resourcesWrapper {
      display: flex;
      justify-content: space-between;
      // height: 100%;
      & > div {
        width: 50%;
        & > div {
          padding-bottom: 5px;
          display: flex;
          flex-direction: column;
          // height: 100%;
          & > div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-height: 1.4rem;
          }
        }
      }
    }
  }

  .fc-time {
    font-size: 1em;
    padding: 0;
  }

  .worklog-title {
    background-color: #a7a7a7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    border-bottom: 1px solid $event-border-color;
    box-sizing: border-box;
    padding: 4px;

    & > div {
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      &:first-child {
        margin-bottom: 5px;
      }
    }
    // &.night-label,
    // &.day-label,
    // &.prev-day-event {
    //     display: flex;
    //     align-items: center;
    //     font-size: 12px;
    //     height: 23px;
    // }

    & > span {
      padding-left: $left-padding;
      width: 175px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .icon::before {
    font-family: foundation-icons;
    display: block;
    position: absolute;
    right: 4px;
  }

  .submited::before {
    content: '\f126';
  }

  .resubmited::before {
    content: '\f16b';
  }

  .prev-day-event {
    background: $prev-day-color;
    color: #eaeaea;
  }

  .resource-droped {
    float: left;
    margin: 5px $left-padding 0;
    clear: both;
  }

  .hidden-resources {
    position: absolute;
    bottom: 0;
    right: 6px;
    color: #000;
  }

  .schedule-event {
    overflow: visible;
    width: 200px;
    border-radius: 5px;
    // min-height: 7.5rem;

    &.fc-not-start {
      .fc-bg,
      .fc-content {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.fc-not-end .fc-bg {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }

    .originalTime {
      background: orangered;
      width: 7px;
      position: absolute;
      top: -1px;
      left: -7px;
      border-radius: 10px;
    }
  }

  .continue {
    padding-right: 20px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: visible;

    &:after {
      content: ' ';
      display: block;
      left: calc(100% - 10px);
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0;
      border-style: solid;
      border-color: #f3f2f2 #f3f2f2 #f3f2f2 #4d4d4d;
      border-width: 11px 0 11px 10px;
    }

    &.day-label {
      &:after {
        border-color: #f3f2f2 #f3f2f2 #f3f2f2 $day-color !important;
      }
    }

    &.no-label {
      &:after {
        border-color: #f3f2f2 #f3f2f2 #f3f2f2 $no-label-color !important;
      }
    }

    &.equipment {
      &:after {
        border-color: transparent transparent transparent $equipment-color !important;
      }
    }
  }

  .previosly {
    padding-left: 20px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:before {
      content: ' ';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-bottom-width: 11px;
      border-top-width: 11px;
      border-left: 10px solid #f3f2f2;
    }
  }

  .equipment {
    background-color: $equipment-color;
    color: #fff;
  }

  .cancel-note-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    position: absolute;
    left: 50%;
    top: calc(50% + 30px);
    transform: translate(-50%, -50%);
    width: 90%;
    max-height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: auto;
    .note-title {
      color: red;
      display: block;
      text-align: center;
      margin-bottom: 5px;
      font-size: 14px;
    }
    .note-content {
      color: #000;
      display: block;
      text-align: center;
    }
  }
}

.prevNextWrapper,
.prevWrapper {
  float: left;

  a {
    padding: 0 !important;
    margin: 0 !important;

    * {
      line-height: 1;
    }
  }
}

.sidebar-style {
  position: fixed;
  top: $topbar-height;
  bottom: 0;
  width: $sidebar-width;
  z-index: 3;
  overflow: auto;
  border-right: solid $border-color 1px;
  background-color: #f5f5f5;
  padding-right: 0;

  $search-height: 41px;

  .search {
    position: fixed;
    width: $sidebar-width - 1;
    color: #aaa;
    font-size: 16px;

    input {
      text-indent: 30px;
    }

    i {
      position: absolute;
      z-index: 10;
      left: 12px;
      top: 5px;
      font-size: 20px;
    }

    input[type='search'] {
      box-sizing: border-box;
      width: 100% !important;
      border: none;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
      position: relative;
      z-index: 3;
      margin: 0 0 4px;
      height: $search-height;
      border-bottom: 1px solid $border-color;
    }
  }

  table.unstriped {
    margin-top: $search-height;
    border: none;
  }

  i.fi-pencil {
    margin-right: 10px;
  }

  .material-header {
    .material-name-and-color {
      margin-bottom: 5px;
    }
    .color-swatch {
      display: inline-block;
      position: relative;
      width: 1em;
      height: 1em;
      border: 1px solid lighten(black, 50%);
      top: 0.1em;
      margin-left: 5px;
    }
    .surface {
      margin-bottom: 5px;
    }
  }
  .material-description {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & > div:first-child {
      min-width: 50px;
    }
  }
}

.top-button-groups {
  position: fixed;
  left: $sidebar-width;
  right: 0;
  z-index: 200;
  background-color: #f3f2f2;
  border-bottom: 1px solid $border-color;

  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  .pull-right {
    float: right;
  }

  .reconcileBtn {
    background: #df2121;
    &:hover {
      background: #aa2727;
    }
    &:disabled {
      background: #388e3c;
      &:hover {
        background: #388e3c;
      }
    }
  }

  select,
  .hasDatepicker {
    margin: 0;
    height: 40px;
    border-width: 1px 1px 0 !important;
    border-color: $border-color;
    min-width: 108px;

    &:focus {
      outline: none !important;
    }
  }

  button {
    margin: 0;
  }

  .button {
    line-height: 2;
    font-size: 14px;
    padding: 6px 23px 6px !important;

    &.actual {
      border-right: 1px solid $border-color;
    }

    &.publish-schedule {
      border-left: 1px solid $border-color;
    }
  }

  .print {
    font-size: 28px;
    line-height: 1;
    padding-right: 11px !important;
    padding-left: 11px !important;
  }

  .customButtons {
    font-size: 33px;
    width: 44px;
    height: 40px;
    line-height: 1.2;
    color: #4d4d4d;
    padding: 0 5px !important;
    display: inline-block;
    vertical-align: top;

    &:hover {
      color: #3a3838;
    }
  }
}

//datepicker styles
.ui-datepicker {
  max-width: 314px;
  width: 100%;
  padding: 0 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  font-size: 16px !important;
  color: #222 !important;

  .ui-widget-header {
    border-width: 0 1px 1px;
    border-color: #adadad;
    background: none;
    padding: 4px 0;

    .ui-datepicker-title {
      font-weight: normal;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
      background-color: #e6e6e6;
      width: 40px;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 0;
      text-align: center;
      padding: 4px 0;

      &:before {
        content: '\f10a';
        display: inline-block;
        vertical-align: top;
        font-family: 'foundation-icons';
        font-size: 28px;
        line-height: 1.1;
        color: #4c4c4c;
      }

      &:hover {
        border: none;
        font-size: 28px;
        font-weight: normal;
      }
    }
    .ui-datepicker-next {
      right: 0;
      left: auto;

      &:before {
        content: '\f10b';
      }
    }
  }

  .ui-datepicker-calendar {
    margin: 0;
    border-spacing: 0 !important;
    border-collapse: collapse !important;

    thead {
      background-color: #d8d8d8;
      border-bottom: 1px solid #adadad;

      th {
        padding: 2px;
        font-weight: normal;
        border: solid #adadad;
        border-width: 0 1px 0 0;

        &:first-child {
          border-width: 0 1px;
        }

        &:last-child {
          // border: none;
        }
      }
    }

    tbody {
      tr {
        background: none;
      }

      td {
        padding: 0;
        border: 1px solid #adadad;

        .ui-state-default {
          padding: 10px;
          text-align: center;
          border: none;
          background: none;
          transition: all 0.3s;

          &.ui-state-active,
          &.ui-state-hover {
            background-color: #008bbc;
            border-color: #adadad;
            color: #fff;
          }
        }
      }
    }
  }

  * {
    border-radius: 0 !important;
  }
}

.prevWrapper {
  border-right: 1px solid #d5d5d5;
}

.datePickerWrapper {
  width: 150px;
  float: left;
  height: 39px;
  position: relative;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 5px 0 4px;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    right: 10px;
    top: 18px;
    z-index: 2;
  }

  #datepicker {
    background: #fff;
  }
}

.scheduleButton {
  padding: 9.5px 20px;
  margin: 0;

  &.active {
    background: #008cba;
    color: #fff;
  }
}

button.todayBtn {
  outline: 0;
}

.button.secondary.scheduleButton:hover {
  background: $primary-color;
  color: #fff;
}

.hide-for-print {
  margin: 0 !important;

  > .columns {
    padding: 0 !important;
  }
}

.schedule-print-version {
  &.row,
  .row {
    margin: inherit;
    width: auto;
  }

  > .columns {
    padding: inherit;
  }
}

.worklog-container {
  border: 1px solid #000;
  margin: 0 0 10px 0;
  page-break-inside: avoid;
}

.worklog-header {
  padding: 10px;
  border-bottom: 1px solid #000;
  h5 {
    font-size: 16px;
  }
}

.worklog-workers-list {
  padding: 10px;
  font-size: 12px;
}
.flex-row {
  display: flex;
}
.flex-item {
  flex-grow: 1;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
}

.time-select {
  width: 30%;
}
.schedule-select-sm {
  width: 50px;
}
.x-remove {
  float: none !important;
  cursor: pointer;

  &:before {
    color: $text-color;
  }
}

#dropModal {
  outline: none;
  padding: 1.15rem 1.4rem 1.4rem;

  .text {
    font-size: 1.1em;
    padding-right: 30px;
    padding-top: 5px;
  }

  hr {
    margin: 0.7rem 0 1.1rem;
  }
}

#editConflictModal {
  outline: none;
  padding: 1.15rem 1.4rem 1.4rem;

  .text {
    font-size: 1.1em;
  }

  .field-wrapper {
    display: flex;
    h6 {
      width: 70%;
      margin: 0;
    }
    input {
      margin: 0;
      margin-bottom: 2px;
      margin-right: 5px;
    }
    label {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  hr {
    margin: 0.7rem 0 1.1rem;
  }
}

label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type='checkbox'].css-checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type='checkbox'].css-checkbox + label.css-label {
  padding-left: 20px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 15px;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox'].css-checkbox:checked + label.css-label {
  background-position: 0 -15px;
}

.css-label {
  background-image: url(http://csscheckbox.com/checkboxes/dark-check-green.png);
}

/*specific classes related to Checkbox skins*/

.lite-green-check {
  background-image: url(http://csscheckbox.com/checkboxes/lite-green-check.png);
}
.lite-blue-check {
  background-image: url(http://csscheckbox.com/checkboxes/lite-blue-check.png);
}
.lite-gray-check {
  background-image: url(http://csscheckbox.com/checkboxes/lite-gray-check.png);
}
.lite-cyan-check {
  background-image: url(http://csscheckbox.com/checkboxes/lite-cyan-check.png);
}
.lite-orange-check {
  background-image: url(http://csscheckbox.com/checkboxes/lite-orange-check.png);
}
.lite-red-check {
  background-image: url(http://csscheckbox.com/checkboxes/lite-red-check.png);
}

.lite-x-cyan {
  background-image: url(http://csscheckbox.com/checkboxes/lite-x-cyan.png);
}
.lite-x-gray {
  background-image: url(http://csscheckbox.com/checkboxes/lite-x-gray.png);
}
.lite-x-blue {
  background-image: url(http://csscheckbox.com/checkboxes/lite-x-blue.png);
}
.lite-x-orange {
  background-image: url(http://csscheckbox.com/checkboxes/lite-x-orange.png);
}
.lite-x-red {
  background-image: url(http://csscheckbox.com/checkboxes/lite-x-red.png);
}
.lite-x-green {
  background-image: url(http://csscheckbox.com/checkboxes/lite-x-green.png);
}

.mac-style {
  background-image: url(http://csscheckbox.com/checkboxes/mac-style.png);
}
.mario-style {
  background-image: url(http://csscheckbox.com/checkboxes/mario-style.png);
}
.alert-style {
  background-image: url(http://csscheckbox.com/checkboxes/alert-style.png);
}
.lite-plus {
  background-image: url(http://csscheckbox.com/checkboxes/lite-plus.png);
}
.dark-plus {
  background-image: url(http://csscheckbox.com/checkboxes/dark-plus.png);
}
.dark-plus-cyan {
  background-image: url(http://csscheckbox.com/checkboxes/dark-plus-cyan.png);
}
.dark-plus-orange {
  background-image: url(http://csscheckbox.com/checkboxes/dark-plus-orange.png);
}
.dark-check-cyan {
  background-image: url(http://csscheckbox.com/checkboxes/dark-check-cyan.png);
}
.dark-check-green {
  background-image: url(http://csscheckbox.com/checkboxes/dark-check-green.png);
}
.dark-check-orange {
  background-image: url(http://csscheckbox.com/checkboxes/dark-check-orange.png);
}

.depressed-lite-small {
  background-image: url(http://csscheckbox.com/checkboxes/depressed-lite-small.png);
}
.elegant {
  background-image: url(http://csscheckbox.com/checkboxes/elegant.png);
}
.depressed {
  background-image: url(http://csscheckbox.com/checkboxes/depressed.png);
}
.chrome-style {
  background-image: url(http://csscheckbox.com/checkboxes/chrome-style.png);
}
.web-two-style {
  background-image: url(http://csscheckbox.com/checkboxes/web-two-style.png);
}
.vlad {
  background-image: url(http://csscheckbox.com/checkboxes/vlad.png);
}
.klaus {
  background-image: url(http://csscheckbox.com/checkboxes/klaus.png);
}

input[type='checkbox'].css-checkbox.med + label.css-label.med {
  padding-left: 22px;
  height: 17px;
  display: inline-block;
  line-height: 17px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 15px;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox'].css-checkbox.med:checked + label.css-label.med {
  background-position: 0 -17px;
}
input[type='checkbox'].css-checkbox.sme + label.css-label.sme {
  padding-left: 22px;
  height: 16px;
  display: inline-block;
  line-height: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 15px;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox'].css-checkbox.sme:checked + label.css-label.sme {
  background-position: 0 -16px;
}
input[type='checkbox'].css-checkbox.lrg + label.css-label.lrg {
  padding-left: 22px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 15px;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox'].css-checkbox.lrg:checked + label.css-label.lrg {
  background-position: 0 -20px;
}

.update-schedule-modal-form {
  .row {
    margin: 0 -10px 5px !important;

    .column {
      padding: 0 10px !important;
    }
  }

  .css-label {
    margin-left: 0 !important;
    background-color: #fff !important;

    &.day-label {
      mark {
        background-color: #f4fe75;
      }
    }

    &.night-label {
      mark {
        background-color: #4d4d4d;
        color: #fff;
      }
    }

    &.no-label {
      mark {
        background-color: #f00 !important;
        color: #eaeaea;
      }
    }

    mark {
      display: inline-block;
      vertical-align: middle;
      margin: -3px 0 0 0;
      padding: 3px 9px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
  }

  button[type='submit'] {
    margin-bottom: 0 !important;
  }
}

.border-error {
  border-color: red !important;
}

.scroll-holder {
  overflow-y: auto;
}

.hide-scroll {
  overflow: hidden;
}

.flex-hours-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px !important;
  & > * > div {
    min-width: 215px;
    & > * {
      margin: 0;
    }
  }
  & > * > span {
    margin: 5px;
  }
  .flex-hours {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 1249px) {
  .flex-hours-wrapper {
    justify-content: space-around;
    .flex-hours {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media screen and (max-width: 640px) {
  #schedule-worklog-form {
    .collapseSides > div {
      padding: 0;
    }
  }
}

@media screen and (min-width: 641px) {
  .user-schedule {
    padding: 20px 0 10px 20px;
  }
  .day-header h4 span {
    display: inline-block;
    padding: 10px;
  }
}
