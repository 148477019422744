.statistic-home-wrapper {
  display: flex;
}

.statistic-card-home-wrapper {
  width: 985px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 40px;
  // justify-content: space-between;
}

.statistic-month-picker-wrapper {
  display: flex;
  width: 980px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.changeMonthIcon {
  cursor: pointer;
}

.changeMonthIcon.active {
  color: #000;
  cursor: pointer;
}

.changeMonthIcon.disable {
  color: rgb(232, 232, 232);
  cursor: default;
}

.changeMonthIcon.rightArrow {
  margin: 0 15px 0 0;
}

.changeMonthIcon.leftArrow {
  margin: 0 5px 0 15px;
}

.edit {
  display: inline-block;
  max-width: 170px;
}

.inlineBlock {
  display: inline-block;
  vertical-align: middle;
}

.info-statistic-block {
  border: 1px solid #6588e8;
  width: 300px;
  border-radius: 10px;
  min-height: 120px;
  box-sizing: border-box;
  padding: 15px 20px;

  &-inactive {
    background-color: #e2e2e2;
    opacity: 0.7;
    width: 255px;
    border-radius: 10px;
    min-height: 120px;
    box-sizing: border-box;
    padding: 15px 20px;
  }
}

.info-statistic-header {
  display: flex;
  justify-content: space-between;
}

.info-statistic-diff {
  font-size: 14px;
  padding-top: 4px;
}

.diff-wrapper {
  display: flex;
  padding-top: 13px;
}

.info-statistic-quantity {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  margin: 0;
}
.info-statistic-text {
  color: #6f6b6b;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
