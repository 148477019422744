.selectedDayName {
  color: #202020;
  font-size: 18px;
  letter-spacing: 0.23px;
  line-height: 1.4;
  text-align: right;
  cursor: pointer;
  user-select: none;
  width: 270px;
}

.dayPickerWrapper {
  position: relative;
}

.dayPickerWrapper .DayPickerInput {
  position: absolute;
  top: 30px;
  right: 0;
}
.dayPickerWrapper .DayPickerInput input {
  opacity: 0;
  height: 2px;
  margin: 0;
  padding: 0;
  pointer-events: none;
}
.dayPickerWrapper .downArrow {
  vertical-align: middle;
  font-size: 1.2rem !important;
}

.dayPickerWrapper .DayPickerInput-Overlay {
  z-index: 100;
}

.dayPickerWrapper.endDatePicker .DayPickerInput-Overlay {
  left: auto;
  right: 0;
}
.fullWidth .DayPickerInput {
  width: 100% !important;
}

.inlineFlexWrapper {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 640px) {
  .inlineFlexWrapper .changeMonthIcon.rightArrow,
  .inlineFlexWrapper .changeMonthIcon.leftArrow {
    display: block;
  }
  .selectedDayName {
    width: auto;
    font-size: 22px;
  }
}

@media (max-width: 370px) {
  .selectedDayName {
    width: auto;
    font-size: 16px;
  }
}
