@import "styles/base/color.scss";


.admin-panel-table-container {

  &.MuiTableContainer-root {
    border-radius: 5px;
    max-height: 600px;
    overflow-y: scroll;
  }

  .MuiTable-root {
    border: none;

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
      color: #6588e8;
    }
  }

  .MuiTableBody-root {
    border: solid 1px $palette-blue-grey-lighter;
  }
  .MuiTableHead-root {
    background: rgba(101, 136, 232, 0.08);
  }

  .MuiTableCell-root {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $palette-blue-grey-lighter;
    font-size: 14px;
  }

  .MuiTableCell-head {
    padding: 10px 25px;
    font-size: 12px;
    flex-direction: row;
    color: $palette-blue-grey-light;
    white-space: nowrap;
    & span {
      cursor: pointer;
    }
    > .MuiTableSortLabel-root {
      margin-right: -8px;
    }

  .MuiTableSortLabel-icon{
    position: absolute;
    left: 100%;
    }
  }

  .MuiTableCell-body {
    padding: 16px 25px;
    font-size: 14px;
    &.inactive {
      color: $palette-grey;
    }
  }

  .MuiTableCell-paddingCheckbox {
    padding: 0;
  }

  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: $palette-blue-grey;
  }
  .MuiTableRow-root.Mui-selected {
    background-color: $palette-blue-grey;
  }


  .cell-button {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    &--gray {
      color: rgb(138, 138, 138);
      cursor: default;
    }
  
    &__icon {
      margin-right: 4px;
    }
  }

  .action-cell {
    width: 1%;
    white-space: nowrap;
    color: $palette-primary;
  }

  .cell-status {
    display: inline-flex;
    width: 92px;
    height: 22px;
    border-radius: 13px;
    align-items: center;
    justify-content: center;
    color: $palette-status-inactive;
    background: $palette-bg-status-inactive;
  
    &.active {
      color: $palette-status-active;
      background: $palette-bg-status-active;
    }
  }
}
.clients-root .MuiTableCell-body {
  & span {
    cursor: default;
  }
}

.users-root .MuiTableBody-root .MuiTableRow-root {
  cursor: pointer;
}
