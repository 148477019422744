.section {
  // padding: 30px 20px 30px 20px;
  width: 1400px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    width: 1320px;
  }
  @media (max-width: 1331px) {
    width: 1150px;
  }
  @media (max-width: 1150px) {
    width: 100%;
  }
}

.main-container-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 25px;
  margin-top: 0;
  @media (max-width: 1024px) {
    display: none;
  }
}
.main-container-information {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1400) {
    flex-wrap: nowrap;
  }
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
}
