.timeclock-report {
  &-day {
    margin-bottom: 35px;

    &-empty {
      margin-bottom: 50px;
    }
  }

  &-summary {
    margin-top: -20px;
  }
}
