.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
  padding: 5px 7px;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}


.InputFromTo .DayPickerInput-Overlay {
  width: 280px;
}
.DayPicker-Day {
  /* padding: 0.4em 0.5em; */
  padding: 8px 7px;
}
@media screen and (max-width: 640px) {
  .DayPickerInput-OverlayWrapper {
    position: initial !important;
  }
  .DayPickerInput-Overlay, .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: 20px !important;
  }
}

.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: 0;
  left: 0
}

.InputFromTo input {
  border: 1px solid #D9DAE3;
  padding: 0.4rem;
  border-radius: 5px;
  box-shadow: none;
  margin: 8px 0;
  width: 110px
}

.DayPickerInput {
  width: auto !important;
  font-family: Poppins,'Open Sans',sans-serif;
}
.InputFromTo-to {
  display: inline-block;
}

.drawerFilters .DayPicker-NavButton {
  top: 1.1em;
  right: 1.5em;
}
/* 
.drawerFilters .DayPicker-Day--today {
  color: #D0021B !important;
} */