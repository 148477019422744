.rootCalendarWrapper {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.calendarWrapper {
  position: absolute;
  z-index: 3000;
  right: 0;
  top: 30px;
  & > div {
    border-radius: 8px;
    overflow: hidden;
  }
  abbr {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }

  .react-calendar__navigation {
    & > * {
      padding: 0;
      color: #202020;
      &:hover,
      &:enabled {
        color: #202020;
      }
    }
  }
  .react-calendar__tile {
    padding: 1.5em 0.2em;
  }
  .react-calendar__month-view__weekdays {
    font-weight: normal;
    font-size: 14px;
  }
  .react-calendar__month-view__days__day,
  .react-calendar__century-view__decades__decade {
    color: #202020;
    padding: 0.75em 0.5em;
    & > * {
      color: inherit;
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__tile--active {
    & > * {
      color: #fff;
    }
  }
  .react-calendar__decade-view__years {
    & > * {
      color: #202020;
    }
  }
  .react-calendar__tile--hasActive {
    color: #fff;
    background: #006edc;
    & > * {
      color: #fff;
      background: #006edc;
    }
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    font-size: 0.8rem;
    line-height: normal;
    height: 42px;
    color: #202020;
    padding: 0.75em 0.5em;
    font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    cursor: pointer;
  }
}

.selectedDaysName {
  color: #202020;
  font-size: 18px;
  letter-spacing: 0.23px;
  line-height: 1.4;
  text-align: right;
  cursor: pointer;
  user-select: none;
}
.rootCalendarWrapper .downArrow {
  vertical-align: middle;
  font-size: 1.2rem !important;
}
