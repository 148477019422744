.btn {
  background-color: transparent;
  min-width: 100px;
  height: 42px;
  color: #a4a5b3 !important;
  border: 1px solid #a4a5b3;
}
.btn:hover {
  background-color: transparent !important;
}
.btn:focus {
  background-color: transparent !important;
}
.buttons .Mui-selected {
  background-color: transparent !important;
  color: #5a87ef !important;
  border: 1px solid #5a87ef !important;
}
