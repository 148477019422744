.timeclock-day-report {
  @media (max-width: 600px) {
    // border: 1px solid #e8e8e8;
    // border-radius: 5px;
  }

  &-wrapper {
    @media (max-width: 999px) {
      border-radius: 5px;
      border: 1px solid #d9dae3;
      margin-bottom: 10px;
    }
    @media print {
      border: none;
      margin-bottom: 0;
    }
  }

  &-type {
    display: none;
    @media (max-width: 999px) {
      display: block;
      color: #202020;
      font-size: 20px;
      font-weight: 500;
      margin-right: 12px;
      letter-spacing: 0.25px;
      width: 650px;
      margin: 5px auto 18px;
    }
    @media (max-width: 680px) {
      width: 500px;
    }
    @media (max-width: 530px) {
      width: auto;
      margin: 5px 0 18px;
    }
    @media print {
      display: none;
    }
  }

  &-manual-override {
    color: #dd6060;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  &-manual-override-value {
    color: #dd6060;
    font-family: 'Lucida Grande';
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }

  &-actions {
    position: absolute;
    top: -36px;
    left: 0;
    right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media (max-width: 999px) {
      position: static;
    }
  }

  &__selected {
    background-color: rgba(244, 247, 254, 1);
  }
  &-add-cip {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #5a87ef;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 0;
    margin-left: 30px;
    margin-bottom: 0;

    &:hover,
    &:focus,
    &:active {
      color: #5a87ef;
      border: none;
      background: none;
    }
  }

  &-edit-hours {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #5a87ef;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 0;
    margin: 0;

    &:hover,
    &:focus,
    &:active {
      color: #5a87ef;
      border: none;
      background: none;
    }
  }
  &-add-cip {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #5a87ef;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 0;
    margin: 0;
    margin-left: 20px;

    &:hover,
    &:focus,
    &:active {
      color: #5a87ef;
      border: none;
      background: none;
    }
  }

  &-label {
    color: #a4a5b3;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 19px;
    @media (max-width: 999px) {
      display: none;
    }
  }
  &-checkbox {
    position: absolute;
    right: 316px;
  }
  &-reconcile {
    color: #e72b1e;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 0;
    margin-left: 30px;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      color: #e72b1e;
      border: none;
      background: none;
    }
  }

  &-date {
    position: relative;
    color: #202020;
    font-family: Poppins;
    font-size: 18px;
    letter-spacing: 0.23px;
    line-height: 27px;
    display: flex;
    text-align: center;
  }
  &-edited {
    font-family: Poppins;
    font-size: 12px;
    color: #a4a5b3;
    margin: 0;
    margin-left: 20px;
  }
  &-time {
    font-family: Poppins;
    font-size: 12px;
    color: #a4a5b3;
    margin: 0;
  }
  &-hours {
    color: #a4a5b3;
    font-family: 'Open Sans';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 21px;
    @media (max-width: 999px) {
      display: none;
    }
    @media print {
      display: block;
    }
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-worklog {
    position: relative;
    border: 1px solid #d9dae3;
    border-radius: 4px;
    background-color: #ffffff;
    @media (max-width: 999px) {
      width: 650px;
      margin: 0 auto;
      border: none;
    }
    @media (max-width: 680px) {
      width: 500px;
    }
    @media (max-width: 530px) {
      width: auto;
      margin: 0;
    }
    margin-top: 15px;
    @media print {
      border: 1px solid #d9dae3;
      border-radius: 4px;
      width: auto;
      margin-top: 15px;
    }

    &-header {
      border: 1px solid #d9dae3;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 22px 16px;
      @media (max-width: 999px) {
        background-color: transparent;
        border: none;
        padding: 22px 65px 0;
      }
      @media (max-width: 530px) {
        padding: 22px 16px 0;
      }
      @media (max-width: 370px) {
        padding: 22px 7px 0;
      }
      @media print {
      border: 1px solid #d9dae3;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 22px 16px;
      }
    }

    &-body {
      @media (max-width: 999px) {
        border: none;
      }
      &-container {
        padding: 13px 16px 32px 16px;
      }
    }
  }
}
