.forgot-pass-wrapper {
  height: 100vh;
  //   overflow: hidden;
  padding: 30px;
}
h2.forgot-pass-title{
  font-size: 2.3125rem;
}

.forgot-pass-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  height: 450px;
  justify-content: space-between;
}

.change-type-link {
  cursor: pointer !important;
  color: #5a87ef !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  // margin: 0 auto !important;
  font-family: 'Open Sans';
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
  display: block !important;
  &:hover,
  &:active,
  &:focus {
    background: none !important;
    border: none !important;
    color: #5a87ef !important;
  }
}
.change-type-label {
  color: #202020;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-right: 6px;
}

.forgot-pass-form {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 100px;
}
.forgot-btn {
  width: 100%;
  outline: none !important;
  box-sizing: border-box;
  border: 1px solid #5a87ef;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 10px 40px;
  color: #5a87ef;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: block;
  margin: 10px 0;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid #5a87ef;
    background-color: #ffffff;
    color: #5a87ef;
  }
}

.forgot-form-title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.forgot-form-info {
  text-align: center;
  font-size: 14px;
}

.back-link {
  display: inline-block;
  padding: 4px;
  color: #5a87ef;
  cursor: pointer;
}
