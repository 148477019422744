.dl {
  margin: 0;
  line-height: 20px;
  position: relative;
}

.dt {
  display: inline-block;
  margin: 0 10px 0 0;
  color: #a4a5b3;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.dd {
  display: inline-block;
  margin: 0;
  color: #202020;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.warning {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 0;
  vertical-align: middle;
  width: 20px;
  height: 18px;
}
.warning-right {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  position: absolute;
}
