@import 'client/schedule.scss';

.fullCalendarWrapper {
  .fc-head {
    display: none;
  }

  .fc-fix-order {
    z-index: 1 !important;
  }
}

.showeRes {
  margin-left: 40px !important;
  width: calc(100% - 40px) !important;
}

.fullCalendarWrapper {
  flex-grow: 1;
  margin-left: 325px;
  width: calc(100% - 325px);
  transition: margin 0.2s ease;

  .fc-scroller > .fc-time-grid,
  .fc-scroller > .fc-day-grid {
    margin-top: 7px;
  }

  .fc-event {
    width: 200px;
    background-color: #fff !important;
    cursor: default;
  }

  @media (max-width: 1050px) {
    .fc-view {
      overflow-x: auto;
      height: calc(100vh - 215px);
    }
    .fc-scroller {
      overflow: inherit !important;
      height: calc(100vh - 215px) !important;
    }
  }
  @media (min-width: 1051px) {
    .fc-view {
      overflow-x: auto;
      height: calc(100vh - 145px);
    }
    .fc-scroller {
      overflow: inherit !important;
      height: calc(100vh - 145px) !important;
    }
  }

  box-sizing: border-box;
  position: relative;

  .fc-day-grid-event {
    .fc-time {
      display: block;
      font-weight: normal;
    }
    & > .fc-content {
      display: block;
      max-width: 100%;
      padding: 0;
    }
    .resourcesWrapper {
      box-sizing: border-box;
      max-height: 110px;
    }
  }

  > .fc-view-container {
    padding: 13px 0 0 10px;
  }

  .fc-axis.fc-time span {
    position: absolute;
    top: -10px;
    right: 2px;
    color: #a4a5b3;
    text-transform: uppercase;
    font-size: 14px;
  }

  .fc-time-grid .fc-slats td {
    height: 79px;
  }

  table td,
  table tr,
  table {
    border: none;
    background-color: #fff;
    margin: 0;
  }

  .fc-time-grid > .fc-bg {
    display: none;
  }

  .fc-head {
    display: none;
  }

  .fc-axis.fc-time {
    position: relative;
    border: none;
  }

  .fc-slats {
    table tr .fc-widget-content:nth-child(2) {
      border-top: 1px solid #e8e8e8;
    }

    tr:last-child > td {
      height: 1px;
    }
  }

  .fc-content {
    border-radius: 5px 5px 0 0;
    z-index: 0;
  }

  .fc-time {
    font-size: 1em;
    padding: 0;
  }
}

.lessMarginLeft {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.moreMarginTop {
  @media (max-width: 1050px) {
    .fc-view {
      height: calc(100vh - 215px - 50px);
    }
    .fc-scroller {
      height: calc(100vh - 215px - 50px) !important;
    }
  }
  @media (min-width: 1051px) {
    .fc-view {
      height: calc(100vh - 145px);
    }
    .fc-scroller {
      height: calc(100vh - 145px - 50px) !important;
    }
  }
}

.fc-time-grid__header {
  display: flex;
  z-index: 999999;
  position: sticky;
  top: 0;
  height: 42px;
  margin-left: 36px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: -7px;
}
