/*** Custom Settings ***/

$hr-color: #ddd;
$hr-margin-top: 0.75rem;
$hr-margin-bottom: 0.6rem;
$small-content-top-padding: 0.3em;
$sidebar-width: 250px;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px !default;

// IMPORT ONCE
// We use this to prevent styles from being loaded multiple times for compenents that rely on other components.
$modules: () !default;
@mixin exports($name) {
  @if (index($modules, $name) == false) {
    $modules: append($modules, $name);
    @content;
  }
}

//
// @functions
//

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  } // Turn 0rem into 0
  @return $value;
}

@function data($attr) {
  @if $namespace {
    @return '[data-' + $namespace + '-' + $attr + ']';
  }

  @return '[data-' + $attr + ']';
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

// OLD EM CALC
// Deprecated: We'll drop support for this in 5.1.0, use rem-calc()
@function emCalc($values) {
  @return rem-calc($values);
}

// OLD EM CALC
// Deprecated: We'll drop support for this in 5.1.0, use rem-calc()
@function em-calc($values) {
  @return rem-calc($values);
}

$row-width: rem-calc(1000);

// We use these as default colors throughout
$primary-color: #008cba;
$secondary-color: #e7e7e7;
$alert-color: #f04124;
$success-color: #43ac6a;
$warning-color: #f08a24;
$info-color: #a0d3e8;

$prev-day-color: #808080;
$no-label-color: #f00;
$day-color: #f4ff75;
$night-color: #4d4d4d;
$equipment-color: #f0592a;
$text-color: #4d4d4d;
$text-inverse-color: #eaeaea;
$bg-color: #f5f5f5;

// Media Query Ranges
$small-range: (0em, 40em);
$medium-range: (40.063em, 64em);
$large-range: (64.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);

$screen: 'only screen';

$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';

$small-up: $screen;
$small-only: '#{$screen} and (max-width: #{upper-bound($small-range)})';

$medium-up: '#{$screen} and (min-width:#{lower-bound($medium-range)})';
$medium-only: '#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})';

$large-up: '#{$screen} and (min-width:#{lower-bound($large-range)})';
$large-only: '#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})';

$xlarge-up: '#{$screen} and (min-width:#{lower-bound($xlarge-range)})';
$xlarge-only: '#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})';

$xxlarge-up: '#{$screen} and (min-width:#{lower-bound($xxlarge-range)})';
$xxlarge-only: '#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})';

// We use these to control the background and border styles
$panel-bg: scale-color(#fff, $lightness: -5%);
$panel-border-style: solid;
$panel-border-size: 1px;

// We use this % to control how much we darken things on hover
$panel-function-factor: -11%;
$panel-border-color: scale-color($panel-bg, $lightness: $panel-function-factor);

//
// TOP BAR
//

// Background color for the top bar
$topbar-bg-color: #333;

// Height and margin
$topbar-height: 45px;
$schedule-margin: 120px;
$topbar-media-query: '';

$topbar-arrows: true; //Set false to remove the triangle icon from the menu item
