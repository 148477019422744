input[type='text'][disabled] {
  height: 40px;
}
checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}
.checkbox label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: -3px;
  border: 2px solid #5a87ef;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #e40909;
  box-sizing: border-box;
}
.checkbox input[type='checkbox'] {
  opacity: 0;
  z-index: 1;
}

.checkbox input[type='checkbox']:checked + label::after {
  font-family: 'FontAwesome';
  content: '';
}
.checkbox input[type='checkbox']:disabled + label {
  opacity: 0.65;
}
.checkbox input[type='checkbox']:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox-info input[type='checkbox']:checked + label::before {
  background-color: #5a87ef;
}
.checkbox-info input[type='checkbox']:checked + label::after {
  color: #fff;
}

input[type='checkbox'].styled:checked + label:after {
  font-family: 'FontAwesome';
  content: '';
}
input[type='checkbox'] .styled:checked + label::before {
  color: #fff;
}
input[type='checkbox'] .styled:checked + label::after {
  color: #fff;
}
input[type='checkbox'] + label {
  margin-right: 0;
  vertical-align: text-top;
  padding-left: 25px;
}
