.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
}
.list_item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  margin-bottom: 14px;
  border-radius: 4px;
  background: #fdfdfd;
  border: 1px solid #d9dae3;
  &:checked {
    color: #6288e8;
    background-color: #6288e8;
  }
  &:active {
    color: #6288e8;
    background-color: #6288e8;
  }

  &:hover,
  &:focus {
    background: #e1ebfe !important;
    border: 1px solid #6288e8 !important;
    border-radius: 4px;
    color: #6288e8;
    cursor: pointer;
    label {
      color: #6288e8;
    }
  }

  &.added {
    background: #6288e8;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &.letter--selected {
    background: #e1ebfe;
    border: 1px solid #6288e8;
    color: #6288e8;
  }

  &.letter--error {
    border-color: #e40909;
    color: #e40909;
  }

  &_label {
    color: #a4a5b3;

    &.added_label {
      color: #ffffff;
    }

    &.letter--error_label {
      color: #e40909;
    }

    &.letter--selected_label {
      background: #e1ebfe;
      color: #6288e8;
    }
  }

  &_label:focus,
  &_label:hover {
    color: #6288e8;
  }
}
.letter--selected .list_item_label.added_label {
  color: #6288e8;
}
