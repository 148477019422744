.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPicker-wrapper:focus,
.DayPicker-NavButton:focus,
.DayPicker-Day:focus {
  outline: none;
}
.DayPicker-NavButton {
  /*top: 0.9em;*/
  right: 4em;
}
.DayPicker-NavButton--prev {
  margin-right: 1.2em !important;
  /*top: 0.96em !important;*/
}
.DayPicker-Day {
  /* padding: 0.5em 0.5em; */
  padding: 8px 7px;
}

.DayPickerInput-Overlay .DayPicker-Day {
  padding: 5px 7px;
}
