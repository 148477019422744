.fullWidth .DayPickerInput {
  width: 100% !important;
}

.sm .DayPickerInput > input {
  font-size: 14px;
  line-height: 0.75;
  padding: 5px;
  margin: 8px 0;
}

@media all {
  .DayPickerInput > input {
    width: 100% !important;
    padding: 5px;
  }
}
