.profile-settings-wrapper {
  padding: 25px;
  .MuiButton-outlinedSecondary:hover,
  .MuiButton-outlinedSecondary:focus {
    color: #a4a5b3 !important;
  }
  .MuiButton-outlinedPrimary:hover,
  .MuiButton-outlinedPrimary:focus {
    color: #5a87ef !important;
  }

  .buttons-block {
    display: flex;
    justify-content: center;
    margin-top: 75px;
  }
  .buttons-block-btn {
    min-width: 160px;
    margin: 10px;
  }

  .edit-contact {
    cursor: pointer;
    vertical-align: inherit;
    margin-right: 15px;
  }
}

.profile-img {
  height: 70px;
  margin: 3px auto 0;
  display: block;
  transform: rotate(-135deg);
}

.profile-img-wrapper {
  width: 80px;
  border: 2px solid #5a87ef;
  height: 80px;
  transform: rotate(135deg);
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  margin-right: 15px;
}

.avatar-block {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 35px;
}

// .avatar-block-info {
// }

.password-toggler {
  color: #5a87ef;
  width: 50px;
  cursor: pointer;
  text-align: center;
}

.settings-devider {
  margin: 25px 0 30px !important;
}

.shift-image {
  height: 16px;
  margin: 0 5px 2px 0;
}

.profile-settings-wrapper .MuiSelect-select.Mui-disabled {
  background-color: #ddd;
  color: rgba(0, 0, 0, 0.75);
}

.emergency-block {
  margin: 25px 0;
}

.new-emergency-block {
  border: 1px solid #5a87ef;
  padding: 20px;
  border-radius: 4px;
  max-width: 950px;
}

.emergency-btns-block {
  display: flex;
  // margin-top: 15px;
  justify-content: flex-end;
  width: 100%;
}

.pass-info-icon {
  width: 15px;
  height: 15px;
  text-align: center;
  color: #d93737;
  border: 1px solid #d93737;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  font-size: 11px;
  line-height: 1.3;
  margin-left: 8px;
}
.tool-info-icon {
  width: 15px;
  height: 15px;
  text-align: center;
  color: #fff;
  background-color: #5a87ef;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
  margin-left: 5px;
}
.pass-info-block {
  display: none;
  width: 260px;
  background-color: #575757;
  position: absolute;
  z-index: 3;
  color: white;
  font-weight: normal;
  text-align: left;
  padding: 11px;
  border-radius: 6px;
  top: 0;
  left: 20px;
  @media screen and (max-width: 999px) {
    // right: 20px;
    left: auto;
  }
}
.pass-info-icon:hover .pass-info-block {
  display: block;
}
.tool-info-icon:hover .pass-info-block {
  display: block;
}

.set-up-pass {
  margin-top: 30px !important;
  // cursor: pointer;
  @media screen and (max-width: 999px) {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
}

.emergency-btns-block p.MuiTypography-colorTextSecondary:hover {
  color: #6d6d70;
}

.error {
  color: #d93737;
  font-size: 11px;
}
