.DayPicker {
  font-family: Poppins;
  font-size: 14px;
  abbr {
    font-family: Poppins;
    font-size: 12.6px;
  }
}

.DayPickerInput-Overlay {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
}

.DayPicker-wrapper {
  padding: 0;
}

.DayPicker-Month {
  margin: 16px 16px 22px;
}

.DayPicker-Caption {
  color: #202020;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 30px;
  margin-bottom: 8px;
  padding: 0;
}

.DayPicker-NavButton {
  width: 31px;
  margin-top: 0;
  /*top: 16px;*/
  right: 32px;
}

.DayPicker-NavBar {
  position: absolute;
  right: -40px;
  top: 4px;
  width: auto;
}

.DayPicker-NavButton--prev {
  background-image: url('./../../assets/images/left-arrow.svg');
}

.DayPicker-NavButton--next {
  background-image: url('./../../assets/images/right-arrow.svg');
}

.DayPicker-Day {
  color: #202020;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 10px 12px;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: transparent;
  color: #5a87ef;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #5a87ef;
  color: white;
}

.DayPicker-Day--hoverRange {
  background-color: transparent !important;
  color: #5a87ef !important;
}

.DayPicker-Day--selectedRange {
  background-color: transparent;
  color: #5a87ef;
}

.DayPicker-Day--selectedRangeStart {
  background-color: transparent;
  color: #5a87ef;
}

.DayPicker-Day--selectedRangeEnd {
  background-color: transparent;
  color: #5a87ef;
}

.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
}

.DayPicker-Day--hoverRange:hover {
}
