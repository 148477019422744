.timeclock-summary {
  padding: 14px;
  border: 1px solid #D9DAE3;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  &-item {
    display: flex;
    align-items: center;

    &-label {
      color: #A4A5B3;
      font-family: "Open Sans";
      font-size: 14px;
      line-height: 19px;
      margin-right: 14px;
    }

    &-time {
      color: #202020;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
      line-height: 30px;
      margin-right: 4px;

      &-danger {
        color: #DD6060;
      }
    }

    &-measure {
      color: #202020;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}
