.badge {
  display: inline-block;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 18px;
  border-radius: 10px;
  background-color: #5A87EF;
  text-align: center;
  padding: 1px 8px;
}

.bg-red {
  background-color: #DD6060;
}
