.selectedDayNameMob {
  cursor: pointer;
  user-select: none;
  border: 1px solid #d9dae3;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-family: Poppins,Open Sans,sans-serif;
  font-size: 0.875rem;
  height: 2.3125rem;
  margin: 8px 0;
  padding: 0.5rem;
  width: 166px;
  box-sizing: border-box;
  transition: border-color 0.15s linear;
  text-align: left;
  line-height: 1.5;
}
.selectedDayNameDisable {
  user-select: none;
  cursor: default;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-family: Poppins,Open Sans,sans-serif;
  font-size: 0.875rem;
  height: 2.3125rem;
  margin: 8px 0;
  padding: 0.5rem;
  width: 166px;
  box-sizing: border-box;
  background-color: #dddddd;
  text-align: left;
  line-height: 1.5;
}
.selectedDayNameActive {
  background: #fafafa;
  border-color: #999;
  text-align: left;
}

.dayPickerWrapperMob {
  position: relative;
}

.dayPickerWrapperMob .DayPickerInput {
  position: absolute;
  top: 45px;
  /* right: 0; */
}
.dayPickerWrapperMob .DayPickerInput input {
  opacity: 0;
  height: 1px;
  margin: 0;
  padding: 0;
  pointer-events: none;
  display: none;
}

.dayPickerWrapperMob .DayPickerInput-Overlay {
  z-index: 100;
}

.dayPickerWrapperMob.endDatePicker .DayPickerInput-Overlay {
  left: auto;
  right: auto;
}
.fullWidth .DayPickerInput {
  /* width: auto !important; */
}

.inlineFlexWrapper {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 999px) {
  .selectedDayNameMob {
    width: 320px;
    margin-bottom: 12px;
  }
  .selectedDayNameDisable {
    width: 320px;
    margin-bottom: 12px;
  }
}
@media (max-width: 680px) {
  .selectedDayNameMob {
    width: 228px;
  }
  .selectedDayNameDisable {
    width: 228px;
  }
}
@media (max-width: 530px) {
  .selectedDayNameMob {
    width: 340px;
  }
  .selectedDayNameDisable {
    width: 340px;
  }
}
@media (max-width: 330px) {
  .selectedDayNameMob {
    width: 300px;
  }
  .selectedDayNameDisable {
    width: 300px;
  }
}
