$arrow: url('../../assets/images/arrow-right-icon-blue.svg');
.users_slider {

  &__invisible {
    visibility: hidden;
  }

  &-container {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    $item: &;
    text-align: center;
    justify-content: center;
    flex: 1;

    &-name {
      display: block;
      color: #A5A5A5;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
      line-height: 30px;
    }

    &-id {
      display: block;
      color: #A4A5B3;
      font-family: "Open Sans";
      font-size: 14px;
      line-height: 19px;
    }

    &_active {
      display: flex;
      align-items: center;
      justify-content: space-between;

      #{$item}-name {
        color: #202020;
      }

      #{$item}-id {
        color: #000000;
      }
    }
  }
}


.users_slider-item-button {
  position: static !important;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  width: 30px;
  height: 18px;
}

.users_slider-item-button:hover,
.users_slider-item-button:focus {
  border: none;
}

.users_slider-item-back-button {
  margin-right: 46px;
  background: transparent $arrow center center/30px 18px no-repeat;
  transform: rotate(180deg);
}

.users_slider-item-back-button:hover,
.users_slider-item-back-button:focus {
  background: transparent $arrow center center/30px 18px no-repeat;
}

.users_slider-item-forward-button {
  margin-left: 46px;
  background: transparent $arrow center center/30px 18px no-repeat;
}

.users_slider-item-forward-button:hover,
.users_slider-item-forward-button:focus {
  background: transparent $arrow center center/30px 18px no-repeat;
}
