.special-role-block {
  width: 580px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 1331px) {
    width: 370px;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    width: 728px;
    margin: 0 auto;
  }
  @media (max-width: 765px) {
    width: 100%;
  }
  @media (max-width: 740px) {
    width: 100%;
    padding: 0 15px;
  }
}
.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.info-block-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
}
.info-item-block {
  border-width: 1px;
  border-style: solid;
  width: 280px;
  min-width: 170px;
  border-radius: 10px;
  min-height: 120px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    width: 350px;
  }
  @media (max-width: 765px) {
    width: 100%;
  }
}
.info-item-block-admin {
  border: 1px solid #6588e8;
  width: 280px;
  min-width: 170px;
  border-radius: 10px;
  min-height: 120px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    width: 350px;
  }
  @media (max-width: 765px) {
    width: 100%;
  }
}
.admin-avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.info-quantity {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  margin: 0;
}
.info-text {
  color: #6f6b6b;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
.info-text-black {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.small {
  width: 170px;
  @media (max-width: 1024px) {
    width: 350px;
  }
  @media (max-width: 765px) {
    width: 100%;
  }
}
