@import "./_settings.scss";

/* Header inside */
.customButtons {
    height: 37px;
    width: 37px;
    padding: 0;
    margin: 0;
    text-align: center;
}
.largeIcon {
    font-size: 35px;
}
/* List of resources next to calendar */
.drag {
    cursor: move;
    user-select: none;
}

#availability-content {
  position: fixed;
  left: $sidebar-width;
  right: 0;
  top: $topbar-height;
  bottom: 0;
  overflow: auto;
  padding: 0 0.9375rem;

  & > .row:first-child {
    padding: 0 0.9375rem;
  }
  .print-wrapper {
      padding: 0 5px;
      margin-bottom: 10px;
    a {
        // margin: 0.3rem 0 0 0;
        margin: 0;
        padding: 0.3rem 1rem;
    }
    i {
        font-size: 1.6rem;
    }
  }
  .request-btn-wrapper {
      padding: 0 5px;
      margin-bottom: 10px;
      .requests-btn {
          padding: 10px 5px 11px;
          margin: 0;
        }
    }

   .timeoff-select-wrapper {
        width: 105px;
        padding: 0 5px;
        margin-bottom: 10px;
        select {
            margin: 0;
            height: 40px;
        }
    }

   .monthControl {
        padding: 0 5px;
        margin-bottom: 10px;
        & > a {
            width: 40px;
            height: 40px;
        }
        select {
            margin: 0;
            height: 40px;
        }
    }

   .subheading {
       line-height: 1;
   }

  .availability-table-wrapper {
      padding: 1rem;
  }

  .flex-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      min-height: 70px;
  }
}

/* Full calendar style change */
#events-calendar {
  table,
  table tr.even,
  table tr.alt,
  table tr:nth-of-type(even) {
    background: none;
  }
}

.fc-event {
    position: relative;
	display: block;
	font-size: .85em;
	line-height: 1.3;
	border-radius: 3px;
	border: 1px solid !important;
	font-weight: normal;
    border-style: none !important;
}
.fc-day-grid-event .fc-content {
    display: inline-block;
    white-space: normal;
    max-width: 80%;
    padding: 3px;
    border-radius: 5px;
}
.fi-x {
    float: right;
}
.fc-ltr .fc-basic-view .fc-day-number {
	text-align: left;
}
.fc-scroller {
    overflow-y: hidden !important;
}
.fc-widget-content-custom {
    position: relative;
}
.fc-day-number-custom {
    position: absolute;
    right: 0;
    bottom: 0;
}
table thead tr th.fc-day-header {
    background-color: lightgray;
    padding: 5px;
}
div.fc-widget-header table {
    margin: 0;
}
.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
    background: none;
	border-color: darkgray;
	border-bottom: 5px;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content, /* for gutter border */
.fc-unthemed .fc-popover {
	border-color: darkgray;
}

.fc-before-today,
.fc-past {
  background: #f5f5f5;
}
