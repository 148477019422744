$palette-grey: #777;
$palette-secondary: #a4a5b3;


$palette-blue: #4a79f8;
$palette-blue-grey: #6588e817;
$palette-blue-grey-light: #8094ae;
$palette-blue-grey-lighter: #c4d8f2;

$palette-primary: #6588e8;


$palette-status-inactive: #DD6060;
$palette-status-active: #0C842B;

$palette-bg-status-inactive: #DD60601A;
$palette-bg-status-active: #E0FFE8;

$header-link: #7E7E7E;
$header-link-active: #3E82FF1A;
$header-primary: #3E82FF;
$header-border: #D5D5D5;
$header-font-family: Poppins,Open Sans,sans-serif;
$header-name: #202020;
