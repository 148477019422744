@import "./../../client/settings";

.viewHours{
  -webkit-font-smoothing: initial;
  b, strong {
    font-weight: 700;
}
  table.time-clock-user .border-top {
    border-top: 1px solid #ddd;
}
  .timeclock-table-description {
    max-width: 225px;
}
  a {
    color: #008cba;
    line-height: inherit;
    text-decoration: none;
}
  table thead tr td, table thead tr th {
    color: #222;
    font-size: .875rem;
    font-weight: 700;
    padding: 0.5rem 0.625rem 0.625rem;
}
table tr td {
  display: table-cell;
  line-height: 1.125rem;
  text-align: left;
  margin: 0;
  color: #222;
    font-size: .875rem;
    padding: 0.5625rem 0.625rem;
    text-align: left;
}
.text-red{
  color: red;
}
  table {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    margin-bottom: 1.25rem;
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
}

  table.time-clock-user {
    border-color: #a0a0a0;
    word-wrap: break-word;
}

  table.time-clock-user tr.header-row {
    background: #f5f5f5;
    font-weight: 700;
}
table.time-clock-user .border-bottom {
  border-bottom: 1px solid #ddd;
}

  .text-center-res {
    margin: 2em 0;
     opacity: .5;
    font-weight: 500;
    text-align: center;
  }

  h2 {
  font-size:2.3125rem;
}

h3 {
  font-size: 1.6875rem;
}

.row,select {
  width: 100%
}

.row {
  margin: 0 auto;
  max-width: 62.5rem
}

.row:after,.row:before {
  content: " ";
  display: table
}

.row:after {
  clear: both
}

.row.collapse>.column,.row.collapse>.columns {
  padding-left: 0;
  padding-right: 0
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0
}

.row .row {
  margin: 0 -.9375rem;
  max-width: none;
  width: auto
}

.row .row:after,.row .row:before {
  content: " ";
  display: table
}

.row .row:after {
  clear: both
}

.row .row.collapse {
  margin: 0;
  max-width: none;
  width: auto
}

.row .row.collapse:after,.row .row.collapse:before {
  content: " ";
  display: table
}

.row .row.collapse:after {
  clear: both
}

.column,.columns {
  padding-left: .9375rem;
  padding-right: .9375rem;
  width: 100%;
  float: left
}

.column+.column:last-child,.column+.columns:last-child,.columns+.column:last-child,.columns+.columns:last-child {
  float: right
}

.column+.column.end,.column+.columns.end,.columns+.column.end,.columns+.columns.end {
  float: left
}

.viewHours-header {
  display: flex;
  align-items: center;
  padding: 40px 20px 0;

  h2 {
    min-width: 450px;
    margin: 0  20px 0 0 !important;
  }

  button {
    padding: 16px;

    &.button {
      position: relative;
      margin-right: 10px;
      width: 42px;
      height: 42px;
      background-color: transparent;
      font-size: 30px;
      line-height: 1;
      border: 2px solid #000;
      border-radius: 50%;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .enter-kiosk-mode {
    margin: 0 0 0 auto !important;
  }

  .export-excel {
    background: inherit ;
    color: #000;
  }

  .enter-edit-mode, .exit-edit-mode {
    background: inherit ;
    color: #000;
  }
}

.travelMoments td{
  padding: 0 0 10px;
}

button[name=sendEmail] {
  background: #fff;
  margin-left: 5px !important;
  i {
    color: #222;
  }
}


//Kiosk exit modal
#exit-kiosk-form {
  padding-top: 15px;
  div.column {
    float: none;
    margin: 0 auto;
  }
  button {
    margin: 15px auto 0;
    display: block;
  }
  div > input  {
    margin-bottom: 0;
  }
  .row:first-child div input {
    margin-bottom: 1rem;
  }
  small.error {
    margin-left: 17%;
  }
}

.filter-header {
  button,
  select,
  input {
    margin: 0;
  }

  @media #{$medium-up} {
    h2 {
      margin: 0;
    }
  }

  select {
    margin-top: 0.5em;
  }

  .filter-search {
    padding: 0;

    @media #{$small-only} {
      input,
      select {
        width: 100% !important;
        margin: 0 0 -1px;
      }
    }
  }

  .column.right {
    // Button Container
    width: auto;
  }

  @media #{$medium-up} {
    margin-bottom: 0.8em;
  }

  & + hr {
    // For materials page only
    margin-top: 0;
  }
}
}