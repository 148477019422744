.box > label {
  color: #202020;
  font-size: 18px;
  letter-spacing: 0.23px;
  line-height: 1.4;
  width: 176px;
  text-align: right;
  font-family: Poppins,Open Sans,sans-serif;
}
.rmp-btn {
  color: #666;
  font-family: Poppins,Open Sans,sans-serif;
}

.rmp-pad,
.rmp-popup {
  width: 17rem !important;
  font-family: Poppins,Open Sans,sans-serif;
}
.rmp-container.show {
  left: -110px !important;
}
.rmp-container li.active {
  background-color: #5a87ef !important;
}
.rmp-container i:hover,
.rmp-container li:not(.active):hover {
  background-color: rgba(90, 135, 239, 0.6) !important;
  color: #fff;
}

.changeMonthIcon {
  vertical-align: sub;
  font-size: 1.2rem !important;
  cursor: pointer;
}
.changeMonthIcon.rightArrow {
  margin: 0 15px 0 0;
}
.changeMonthIcon.leftArrow {
  margin: 0 5px 0 15px;
}
.edit {
  display: inline-block;
  max-width: 170px;
}

.inlineBlock {
  display: inline-block;
  vertical-align: middle;
}

/* responsive */

@media (max-width: 640px) {
  .changeMonthIcon.rightArrow,
  .changeMonthIcon.leftArrow {
    display: none;
  }
  .rmp-popup {
    top: 145px !important;
    left: 115px !important;
    width: 18rem !important;
  }
  .box > label {
    text-align: left;
  }
}
