.chart-wrapper {
  max-width: 310px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    max-width: 340px;
  }
  @media (max-width: 740px) {
    margin-bottom: 30px;
  }
  @media (max-width: 576px) {
    width: 100%;
    max-width: none;
  }
}
.chart-wrapper-title {
  font-family: 'Poppins', 'Open Sans', 'sans-serif';
  color: #333333;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 25px;
}
.chart-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins';
  @media (max-width: 1024px) {
    align-items: center;
    flex-direction: row;
  }
  @media (max-width: 567px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.chart-circle-wrapper {
  width: 160px;
  height: 160px;
  // overflow: hidden;
  border-radius: 50%;
  // background: #6488e8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px;
  @media (max-width: 980px) {
    margin: 0;
  }
  @media (max-width: 567px) {
    margin: 0 auto 30px;
  }
}
.chart-circle-wrapper-empty {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #e1a166;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}
.canvas-chart {
  width: 160px;
}
.legend-wrapper {
  display: flex;
  margin-left: 0;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-left: 10px;
  }
  @media (max-width: 567px) {
    width: 100%;
    margin-left: 0;
    flex-direction: row;
    justify-content: space-around;
  }
}
.legend-block {
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
}
.pto-allowed-color {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #e1a166;
}
.pto-designeted-color {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #6588e8;
  margin-left: 10px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}
.legend-text {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #767272;
  margin-left: 10px;
  margin-bottom: 0;
}
