@import "styles/base/color.scss";


.super-admin-filters-bar-wrapper {
  column-gap: 20px;
  padding: 24px 0;
  align-content: center;
  justify-content: center;

  .filters-search-form {
    display: flex;
    flex-grow: 1;

    .search-field {
      width: 100%;
      height: 32px;
      margin: 0;


      input {
        padding: 6px 10px 6px 0;
      }
    }

    .search-icon {
      color: $palette-secondary;
      pointer-events: none;
      margin-left: 8px;
      margin-right: -8px;
      margin-bottom: -4px;
    }
  }

  .filters-button {
    height: 32px;

    &__icon {
      color: $palette-grey;
      margin-right: 10px;
    }
  }
}
