.countdown-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  @media (max-width: 1024px) {
    width: 728px;
    margin: 0 auto 15px;
  }
  @media (max-width: 740px) {
    padding: 0 15px;
    width: 100%;
  }
}

.countdown-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #333333;
  font-size: 25px;
  margin: 0px 0px 20px;
}

.countdown {
  width: 380px;
  display: flex;
  font-size: 30px;
  border: 1px solid #6588e8;
  border-radius: 10px;
  height: 105px;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  @media (max-width: 1024px) {
    width: 350px;
    height: 90px;
  }
  @media (max-width: 499px) {
    width: 100%;
    height: 90px;
  }
}

.countdown-number {
  padding: 0 5px 0;
  border-radius: 3px;
  display: inline-block;
  color: black;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.countdown-time {
  padding: 0 10px 10px;
  border-radius: 3px;
  display: inline-block;
  width: 60px;
  text-align: center;
  box-sizing: inherit;
}

.countdown-time-left-align {
  text-align: left;
}

.countdown-text {
  display: block;
  font-size: 14px;
  color: #6f6b6b;
  font-weight: normal;
}
.separator {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans';
  color: black;
  font-weight: bold;
  font-size: 32px;
  display: inline-block;
  margin-bottom: 33px;
}
.countdown-weather-wrapp {
  display: flex;
}
.weather-block {
  border: 1px solid #6588e8;
  border-radius: 10px;
  height: 105px;
  width: 170px;
  box-sizing: border-box;
  padding: 20px;
  margin-left: 50px;
  @media (max-width: 1024px) {
    display: none;
  }
}
.info-quantity {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin: 0;
}
.info-text-black {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
.weather-avatar {
  margin-left: 30px;
  display: inline;
  color: #6588e8;
}
